import {
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import SettingsEditor from "components/SettingsEditor/SettingsEditor";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";

const SettingsUI = () => {

  const [debug, setDebug] = useStateWithSessionStorage(false, "debug");

  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "20px",
            justifyContent: "space-between",
            background: "var(--header)",
            color: "#fff",
            height: "6vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Typography
              fontWeight={"bold"}
              variant={"h6"}
              style={{
                textTransform: "uppercase",
                color: "#fff",
                paddingLeft: "1rem",
              }}
            >
              &nbsp;Settings
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} style={{ padding: "1rem 1.5rem 0 1.5rem" }}>
        <FormControlLabel
          control={
            <Switch
              checked={debug}
              onChange={(event) => {
                setDebug(event.target.checked);
              }}
              name="debug"
              color="primary"
            />
          }
          label="Debug Mode"
        />
        
        <SettingsEditor />

      </Grid>
    </Grid>
  );
};

export default SettingsUI;
