import { useState } from "react";
import { useQuery } from "react-query";

import { FetchError } from "exceptions/exceptions";
import { API_URL } from "util/network/common";
import styles from "./GlobalMessage.module.css";

const ENDPOINT = `${API_URL}/v1/ecommerce/overview`;

export const apiFetchGlobalMessage = async (): Promise<{ [key: string]: string }> => {
    // // Return test data for now
    // return new Promise((resolve) => {
    //     setTimeout(() => {
    //         resolve({
    //             dk: "Vi oplever forsinkelser på grund af helligdage. Tak for din tålmodighed!",
    //             gb: "We are experiencing holiday delays. Thank you for your patience!"
    //         });
    //     }, 500); // Simulate slight delay
    // });

    // Uncomment below to use actual API call
    const res = await fetch(`${ENDPOINT}/globalmessage`, {
        headers: { "Content-Type": "application/json" },
    });
    if (res.ok) return res.json();
    else if (res.status === 404) return {};
    else throw new FetchError(res.statusText, res.status);
};

const GlobalMessage = () => {
    const [isVisible, setIsVisible] = useState(true);
    const { data, isLoading, isError } = useQuery("globalMessage", apiFetchGlobalMessage);

    if (!isVisible || isLoading || isError || !data) return null;

    // Filter out non-blank messages
    const nonBlankMessages = Object.entries(data).filter(([_, msg]) => msg && msg.trim() !== "");

    if (nonBlankMessages.length === 0) return null;

    return (
        <div className={styles.globalMessageContainer}>
            <span>
                Customers are viewing the following messages:
                <ul>
                    {nonBlankMessages.map(([lang, msg]) => (
                        <li key={lang}>{lang}: {msg}</li>
                    ))}
                </ul>
            </span>
            <button onClick={() => setIsVisible(false)} className={styles.closeButton}>X</button>
        </div>
    );
};

export default GlobalMessage;
