import React from "react";
import { render } from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { dangerColor, primaryColor, whiteColor } from "./assets/colors";
import { ThemeProvider } from "@emotion/react";
import { AppStateProvider } from "./contexts/AppStateContext";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ToastProvider } from "contexts/ToastContext";
import { QueryClient, QueryClientProvider } from "react-query";
import GlobalMessage from "./components/GlobalMessage/GlobalMessage";

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor[0],
      contrastText: whiteColor,
    },
    secondary: {
      main: dangerColor[1],
      contrastText: whiteColor,
    },
    disabled: {
      main: "#F8F8F8",
      contrastText: whiteColor,
    },
  },
});

console.log(process.env.REACT_APP_VERSION);
console.log(process.env.NODE_ENV);
console.log(process.env.REACT_APP_NODE_ENV);

if (
  process.env.REACT_APP_VERSION &&
  process.env.REACT_APP_NODE_ENV === "development"
) {
  Sentry.init({
    dsn: "https://f3366f1b06f0491d8abe3973ef1827a4@o994889.ingest.sentry.io/4504333236174848",
    integrations: [new BrowserTracing()],
    release: "easypartz-dashboard@" + process.env.REACT_APP_VERSION,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const queryClient = new QueryClient();

const container = document.getElementById("root");
render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <GlobalMessage />
          <AppStateProvider>
            <ToastProvider>
              <App />
            </ToastProvider>
          </AppStateProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  container
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
