import { API_URL } from "./common";

export interface Setting {
  id: number;
  name: string;
  value: any;
  default: any;
  created: string;
  modified: string | null;
}

export const getSettings = async (token: string): Promise<Setting[]> => {
  const response = await fetch(`${API_URL}/v1/ecommerce/settings`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  // Error handling
  if (!response.ok) {
    let errorMessage = `Failed to fetch settings: ${response.status} ${response.statusText}`;
    
    try {
      const errorResponse = await response.json();
      if (errorResponse.error) {
        errorMessage = `Failed to fetch settings: ${errorResponse.error}`;
      }
    } catch (err) {
      // Response body was not JSON, fallback to status message
    }

    throw new Error(errorMessage);
  }

  return response.json();
};

export const getSettingByName = async (token: string, name: string): Promise<Setting> => {
  const response = await fetch(`${API_URL}/v1/ecommerce/settings/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  // Error handling
  if (!response.ok) {
    let errorMessage = `Failed to fetch setting '${name}': ${response.status} ${response.statusText}`;
    
    try {
      const errorResponse = await response.json();
      if (errorResponse.error) {
        errorMessage = `Failed to fetch setting '${name}': ${errorResponse.error}`;
      }
    } catch (err) {
      // Response body was not JSON, fallback to status message
    }

    throw new Error(errorMessage);
  }

  return response.json();
};

export const updateSetting = async (token: string, name: string, value: any): Promise<Setting> => {
  const response = await fetch(`${API_URL}/v1/ecommerce/settings/${name}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ value })
  });

  // Error handling
  if (!response.ok) {
    let errorMessage = `Failed to update setting '${name}': ${response.status} ${response.statusText}`;
    
    try {
      const errorResponse = await response.json();
      if (errorResponse.error) {
        errorMessage = `Failed to update setting '${name}': ${errorResponse.error}`;
      }
    } catch (err) {
      // Response body was not JSON, fallback to status message
    }

    throw new Error(errorMessage);
  }

  return response.json();
};