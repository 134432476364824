import React, { useState, useEffect, useContext } from "react";
import AppStateContext from "contexts/AppStateContext";
import { useToast } from "contexts/ToastContext";
import { Setting, getSettings, updateSetting } from "util/network/Settings";
import "./SettingsEditor.css";

const formatDate = (datetime: string | null) => 
    datetime ? `Last edit ${datetime.slice(0, 16)}` : "Never modified";

const SettingsRow = ({ setting }: { setting: Setting }) => {
    const { token } = useContext(AppStateContext);
    const { addToast } = useToast();
    const [value, setValue] = useState(JSON.stringify(setting.value, null, 2));
    const [isValid, setIsValid] = useState(true);
    const [isModified, setIsModified] = useState(false);

    // Update isValid & isModified
    useEffect(() => {
        try {
            if (typeof setting.default === "boolean") {
                // Validate if value is strictly a boolean (true or false)
                setIsValid(value === "true" || value === "false");
            } else if (typeof setting.default === "number") {
                // Convert value to a number and check if it's valid
                const numValue = Number(JSON.parse(value));
                setIsValid(!Number.isNaN(numValue))
                // Would have preferred this to also detect int vs float, but json.parse converts incorrectly if float value has no remainder.
            } else if (typeof setting.default === "string") {
                // Ensure value is a string
                setIsValid(typeof value === "string");
            } else {
                // Attempt to parse the value for other data types (e.g., objects, arrays)
                let parsedValue = JSON.parse(typeof value === "string" ? value : JSON.stringify(value, null, 2));
                setIsValid(parsedValue);
            }

            setIsModified(value !== JSON.stringify(setting.value, null, 2));
        } catch {
            setIsValid(false);
        }
    }, [setting, value]);
    

    // Update value on backend
    const handleUpdate = async () => {
        try {
            let parsedValue = JSON.parse(value);
            console.log(parsedValue)
            const updatedSetting = await updateSetting(token, setting.name, parsedValue);

            setting.value = updatedSetting.value;
            setting.modified = updatedSetting.modified;
            setIsModified(false)
    
            addToast({
                type: "success",
                message: `Updated setting: ${setting.name}`,
            });
        } catch (error) {
            console.error("Failed to update setting:", error);
    
            // Extract error message from thrown Error object
            let errorMessage = error instanceof Error ? error.message : "Unknown error occurred";
    
            addToast({
                type: "error",
                message: errorMessage,
            });
        }
    };
    

    return (
        <tr className="top-align">
            <td className="name-column">
                <span className="italic">{setting.name}</span>
                <div className="last-edit">
                    {formatDate(setting.modified)}
                    <span className="tooltip-container">
                        <span className="info-icon">ℹ️</span>
                        <span className="tooltip-text">Default: {JSON.stringify(setting.default)}</span>
                    </span>
                </div>
            </td>
            <td className="value-column">
                {typeof setting.default === "boolean" ? (
                    <input type="checkbox" 
                        checked={JSON.parse(value)} 
                        onChange={(e) => setValue(JSON.stringify(e.target.checked))} 
                        className={!isValid ? "invalid-input" : ""}
                    />
                ) : typeof setting.default === "number" ? (
                    <input type="number" 
                        value={JSON.parse(value)} 
                        onChange={(e) => setValue(JSON.stringify(e.target.value))}   
                        className={!isValid ? "invalid-input" : ""}
                    />
                ) : typeof setting.default === "string" ? (
                    <input type="text" 
                        value={JSON.parse(value)} 
                        onChange={(e) => setValue(JSON.stringify(e.target.value))}     
                        className={!isValid ? "invalid-input" : ""}
                    />
                ) : (
                    <textarea
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        className={!isValid ? "invalid-input" : ""}
                    />
                )}
            </td>
            <td>
                <button className="update-button" onClick={handleUpdate} disabled={!isValid || !isModified}>
                    Update
                </button>
            </td>
        </tr>
    );
};

const SettingsEditor: React.FC = () => {
    const { token } = useContext(AppStateContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [settings, setSettings] = useState<Setting[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const data: Setting[] = await getSettings(token);
                setSettings(data);
            } catch (error) {
                console.error("Failed to fetch settings:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, [token]);

    const filteredSettings = settings.filter((setting) =>
        setting.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) {
        return <div>Loading settings...</div>;
    }

    return (
        <div className="settings-container">
            <input
                type="text"
                placeholder="Search settings..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-bar"
            />
            <table>
                <thead>
                    <tr>
                        <th className="left-align">Name</th>
                        <th className="left-align">Value</th>
                        <th className="left-align">Update</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredSettings.map((setting) => (
                        <SettingsRow key={setting.id} setting={setting} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SettingsEditor;
